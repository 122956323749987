export const StudentData=[
  {
    student_id:'0',
    student_name:'abdu',
    email:'email',
    phone_number:'392932',
    start_subscreiption:'23',
    end_subscreiption:'55',
    courses:[
      {
        course_id:'0',
        course_name:'Course_name',
        course_price:'33',
      },
      {
        course_id:'0',
        course_name:'Course_name',
        course_price:'33',
      },
      {
        course_id:'0',
        course_name:'Course_name',
        course_price:'33',
      },
      {
        course_id:'0',
        course_name:'Course_name',
        course_price:'33',
      },
      {
        course_id:'0',
        course_name:'Course_name',
        course_price:'33',
      },
    ]
  },
  {
    student_id:'1',
    student_name:'abdu',
    email:'email',
    phone_number:'392932',
    start_subscreiption:'23',
    end_subscreiption:'55',
    courses:[
      {
        course_id:'0',
        course_name:'Course_name',
        course_price:'33',
      },
      {
        course_id:'0',
        course_name:'Course_name',
        course_price:'33',
      },
      {
        course_id:'0',
        course_name:'Course_name',
        course_price:'33',
      },
      {
        course_id:'0',
        course_name:'Course_name',
        course_price:'33',
      },
      {
        course_id:'0',
        course_name:'Course_name',
        course_price:'33',
      },
    ]
  },
  {
    student_id:'2',
    student_name:'abdu',
    email:'email',
    phone_number:'392932',
    start_subscreiption:'23',
    end_subscreiption:'55',
    courses:[
      {
        course_id:'0',
        course_name:'Course_name',
        course_price:'33',
      },
      {
        course_id:'0',
        course_name:'Course_name',
        course_price:'33',
      },
      {
        course_id:'0',
        course_name:'Course_name',
        course_price:'33',
      },
      {
        course_id:'0',
        course_name:'Course_name',
        course_price:'33',
      },
      {
        course_id:'0',
        course_name:'Course_name',
        course_price:'33',
      },
    ]
  },
]
