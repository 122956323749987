const BookData = [
  {
    id: "#DS0215",
    date: "07 Oct, 2021",
    BookName: "Connie Franco",CourseName: "Connie Franco",
    BookPrice: "$26.30",
    Status: "Paid",
    Hours: "38",
    description:
      " A book description serves to state the rationale for the book and give an overview of key content covered, skills and knowledge to be learned, and how it will benefit the student",
    image:
      "https://leverageedublog.s3.ap-south-1.amazonaws.com/blog/wp-content/uploads/2020/04/01170800/Free-Online-Books-with-Certificates.jpg",
    Action: "View Details",
  },
  {
    id: "#DS0214",
    date: "05 Oct, 2021",
    BookName: "Paul Reynolds",CourseName: "Connie Franco",
    BookPrice: "$24.20",
    Status: "Paid",
    Hours: "38",
    description:
      " A book description serves to state the rationale for the book and give an overview of key content covered, skills and knowledge to be learned, and how it will benefit the student",
    image:
      "https://leverageedublog.s3.ap-south-1.amazonaws.com/blog/wp-content/uploads/2020/04/01170800/Free-Online-Books-with-Certificates.jpg",
    Action: "View Details",
  },
  {
    id: "#DS0214",
    date: "06 Oct, 2021",
    BookName: "Ronald Patterson",CourseName: "Connie Franco",
    BookPrice: "$65.32",
    Status: "Pending",
    Hours: "38",
    description:
      " A book description serves to state the rationale for the book and give an overview of key content covered, skills and knowledge to be learned, and how it will benefit the student",
    image:
      "https://leverageedublog.s3.ap-south-1.amazonaws.com/blog/wp-content/uploads/2020/04/01170800/Free-Online-Books-with-Certificates.jpg",
    Action: "View Details",
  },
  {
    id: "#DS0212",
    date: "07 Oct, 2021",
    BookName: "Adella Perez",CourseName: "Connie Franco",
    BookPrice: "$53.32",
    Status: "Paid",
    Hours: "38",
    description:
      " A book description serves to state the rationale for the book and give an overview of key content covered, skills and knowledge to be learned, and how it will benefit the student",
    image:
      "https://leverageedublog.s3.ap-south-1.amazonaws.com/blog/wp-content/uploads/2020/04/01170800/Free-Online-Books-with-Certificates.jpg",
    Action: "View Details",
  },
  {
    id: "#DS0211",
    date: "07 Oct, 2021",
    BookName: "Theresa Mayers",CourseName: "Connie Franco",
    BookPrice: "$13.21",
    Status: "Paid",
    Hours: "38",
    description:
      " A book description serves to state the rationale for the book and give an overview of key content covered, skills and knowledge to be learned, and how it will benefit the student",
    image:
      "https://leverageedublog.s3.ap-south-1.amazonaws.com/blog/wp-content/uploads/2020/04/01170800/Free-Online-Books-with-Certificates.jpg",
    Action: "View Details",
  },
  {
    id: "#DS0210",
    date: "06 Oct, 2021",
    BookName: "Michael Wallace",CourseName: "Connie Franco",
    BookPrice: "$23.41",
    Status: "Pending",
    Hours: "38",
    description:
      " A book description serves to state the rationale for the book and give an overview of key content covered, skills and knowledge to be learned, and how it will benefit the student",
    image:
      "https://leverageedublog.s3.ap-south-1.amazonaws.com/blog/wp-content/uploads/2020/04/01170800/Free-Online-Books-with-Certificates.jpg",
    Action: "View Details",
  },
  {
    id: "#DS0209",
    date: "07 Oct, 2021",
    BookName: "Oliver Gonzales",CourseName: "Connie Franco",
    BookPrice: "$41.23",
    Status: "Pending",
    Hours: "38",
    description:
      " A book description serves to state the rationale for the book and give an overview of key content covered, skills and knowledge to be learned, and how it will benefit the student",
    image:
      "https://leverageedublog.s3.ap-south-1.amazonaws.com/blog/wp-content/uploads/2020/04/01170800/Free-Online-Books-with-Certificates.jpg",
    Action: "View Details",
  },
  {
    id: "#DS0208",
    date: "08 Oct, 2021",
    BookName: "David Burke",CourseName: "Connie Franco",
    BookPrice: "$32.25",
    Status: "Paid",
    Hours: "38",
    description:
      " A book description serves to state the rationale for the book and give an overview of key content covered, skills and knowledge to be learned, and how it will benefit the student",
    image:
      "https://leverageedublog.s3.ap-south-1.amazonaws.com/blog/wp-content/uploads/2020/04/01170800/Free-Online-Books-with-Certificates.jpg",
    Action: "View Details",
  },
  {
    id: "#DS0207",
    date: "09 Oct, 2021",
    BookName: "Willie Verner",CourseName: "Connie Franco",
    BookPrice: "$53.21",
    Status: "Pending",
    Hours: "38",
    description:
      " A book description serves to state the rationale for the book and give an overview of key content covered, skills and knowledge to be learned, and how it will benefit the student",
    image:
      "https://leverageedublog.s3.ap-south-1.amazonaws.com/blog/wp-content/uploads/2020/04/01170800/Free-Online-Books-with-Certificates.jpg",
    Action: "View Details",
  },
  {
    id: "#DS0206",
    date: "07 Oct, 2021",
    BookName: "Felix Perry",CourseName: "Connie Franco",
    BookPrice: "$63.21",
    Status: "Paid",
    Hours: "38",
    description:
      " A book description serves to state the rationale for the book and give an overview of key content covered, skills and knowledge to be learned, and how it will benefit the student",
    image:
      "https://leverageedublog.s3.ap-south-1.amazonaws.com/blog/wp-content/uploads/2020/04/01170800/Free-Online-Books-with-Certificates.jpg",
    Action: "View Details",
  },
  {
    id: "#SK4526",
    date: "05 Oct, 2021",
    BookName: "Stacie Parker",CourseName: "Connie Franco",
    BookPrice: "$63.85",
    Status: "Pending",
    Hours: "38",
    description:
      " A book description serves to state the rationale for the book and give an overview of key content covered, skills and knowledge to be learned, and how it will benefit the student",
    image:
      "https://leverageedublog.s3.ap-south-1.amazonaws.com/blog/wp-content/uploads/2020/04/01170800/Free-Online-Books-with-Certificates.jpg",
    Action: "View Details",
  },
  {
    id: "#SK8522",
    date: "06 Oct, 2021",
    BookName: "Betty Wilson",CourseName: "Connie Franco",
    BookPrice: "$32.12",
    Status: "Pending",
    Hours: "38",
    description:
      " A book description serves to state the rationale for the book and give an overview of key content covered, skills and knowledge to be learned, and how it will benefit the student",
    image:
      "https://leverageedublog.s3.ap-south-1.amazonaws.com/blog/wp-content/uploads/2020/04/01170800/Free-Online-Books-with-Certificates.jpg",
    Action: "View Details",
  },
  {
    id: "#SK4545",
    date: "07 Oct, 2021",
    BookName: "Roman Crabtree",CourseName: "Connie Franco",
    BookPrice: "$45.62",
    Status: "Paid",
    Hours: "38",
    description:
      " A book description serves to state the rationale for the book and give an overview of key content covered, skills and knowledge to be learned, and how it will benefit the student",
    image:
      "https://leverageedublog.s3.ap-south-1.amazonaws.com/blog/wp-content/uploads/2020/04/01170800/Free-Online-Books-with-Certificates.jpg",
    Action: "View Details",
  },
  {
    id: "#SK9652",
    date: "07 Oct, 2021",
    BookName: "Marisela Butler",CourseName: "Connie Franco",
    BookPrice: "$62.35",
    Status: "Pending",
    Hours: "38",
    description:
      " A book description serves to state the rationale for the book and give an overview of key content covered, skills and knowledge to be learned, and how it will benefit the student",
    image:
      "https://leverageedublog.s3.ap-south-1.amazonaws.com/blog/wp-content/uploads/2020/04/01170800/Free-Online-Books-with-Certificates.jpg",
    Action: "View Details",
  },
  {
    id: "#SK4256",
    date: "06 Oct, 2021",
    BookName: "Roger Slayton",CourseName: "Connie Franco",
    BookPrice: "$45.62",
    Status: "Paid",
    Hours: "38",
    description:
      " A book description serves to state the rationale for the book and give an overview of key content covered, skills and knowledge to be learned, and how it will benefit the student",
    image:
      "https://leverageedublog.s3.ap-south-1.amazonaws.com/blog/wp-content/uploads/2020/04/01170800/Free-Online-Books-with-Certificates.jpg",
    Action: "View Details",
  },
  {
    id: "#SK4125",
    date: "07 Oct, 2021",
    BookName: "Barbara Torres",CourseName: "Connie Franco",
    BookPrice: "$42.63",
    Status: "Paid",
    Hours: "38",
    description:
      " A book description serves to state the rationale for the book and give an overview of key content covered, skills and knowledge to be learned, and how it will benefit the student",
    image:
      "https://leverageedublog.s3.ap-south-1.amazonaws.com/blog/wp-content/uploads/2020/04/01170800/Free-Online-Books-with-Certificates.jpg",
    Action: "View Details",
  },
  {
    id: "#SK6523",
    date: "08 Oct, 2021",
    BookName: "Daniel Rigney",CourseName: "Connie Franco",
    BookPrice: "$32.54",
    Status: "Pending",
    Hours: "38",
    description:
      " A book description serves to state the rationale for the book and give an overview of key content covered, skills and knowledge to be learned, and how it will benefit the student",
    image:
      "https://leverageedublog.s3.ap-south-1.amazonaws.com/blog/wp-content/uploads/2020/04/01170800/Free-Online-Books-with-Certificates.jpg",
    Action: "View Details",
  },
  {
    id: "#SK6563",
    date: "09 Oct, 2021",
    BookName: "Kenneth Linck",CourseName: "Connie Franco",
    BookPrice: "$52.62",
    Status: "Pending",
    Hours: "38",
    description:
      " A book description serves to state the rationale for the book and give an overview of key content covered, skills and knowledge to be learned, and how it will benefit the student",
    image:
      "https://leverageedublog.s3.ap-south-1.amazonaws.com/blog/wp-content/uploads/2020/04/01170800/Free-Online-Books-with-Certificates.jpg",
    Action: "View Details",
  },
];

export { BookData };
