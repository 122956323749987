const data = [
    [
        "Jonathan",
        "jonathan@example.com",
        "Senior Implementation Architect",
        "Hauck Inc",
        "Holy See"
    ],
    [
        "Harold",
        "harold@example.com",
        "Forward Creative Coordinator",
        "Metz Inc",
        "Iran"
    ],
    [
        "Shannon",
        "shannon@example.com",
        "Legacy Functionality Associate",
        "Zemlak Group",
        "South Georgia"
    ],
    [
        "Robert",
        "robert@example.com",
        "Product Accounts Technician",
        "Hoeger",
        "San Marino"
    ],
    [
        "Noel",
        "noel@example.com",
        "Customer Data Director",
        "Howell - Rippin",
        "Germany"
    ],
    [
        "Traci",
        "traci@example.com",
        "Corporate Identity Director",
        "Koelpin - Goldner",
        "Vanuatu"
    ],
    [
        "Kerry",
        "kerry@example.com",
        "Lead Applications Associate",
        "Feeney, Langworth and Tremblay",
        "Niger"
    ],
    [
        "Patsy",
        "patsy@example.com",
        "Dynamic Assurance Director",
        "Streich Group",
        "Niue"
    ],
    [
        "Cathy",
        "cathy@example.com",
        "Customer Data Director",
        "Ebert, Schamberger and Johnston",
        "Mexico"
    ],
    [
        "Tyrone",
        "tyrone@example.com",
        "Senior Response Liaison",
        "Raynor, Rolfson and Daugherty",
        "Qatar"
    ],
]

const columns = [
    "Name",
    "Email",
    "Position",
    "Company",
    "Country"
]

const subdata = [
    [
        "Jonathan",
        "jonathan@example.com",
        "01212733888",
        "12-7-2022",
        "12-10-2022",
    ],
    [
        "Harold",
        "harold@example.com",
        "01212733888",
        "12-7-2022",
        "12-10-2022",
    ],
    [
        "Shannon",
        "shannon@example.com",
        "01212733888",
        "12-7-2022",
        "12-10-2022",
    ],
    [
        "Robert",
        "robert@example.com",
        "01212733888",
        "12-7-2022",
        "12-10-2022",
    ],
    [
        "Noel",
        "noel@example.com",
        "01212733888",
        "12-7-2022",
        "12-10-2022",
    ],
    [
        "Traci",
        "traci@example.com",
        "01212733888",
        "12-7-2022",
        "12-10-2022",
    ],
    [
        "Kerry",
        "kerry@example.com",
        "01212733888",
        "12-7-2022",
        "12-10-2022",
    ],
    [
        "Patsy",
        "patsy@example.com",
        "01212733888",
        "12-7-2022",
        "12-10-2022",
    ],
    [
        "Cathy",
        "cathy@example.com",
        "01212733888",
        "12-7-2022",
        "12-10-2022",
    ],
    [
        "Tyrone",
        "tyrone@example.com",
        "01212733888",
        "12-7-2022",
        "12-10-2022",
    ],
]

const subcolumns = [
    "Name",
    "Email",
    "Phone Number",
    "Start Subscription",
    "End Subscription"
]

const studentdata = [
    [
        "Jonathan",
        "jonathan@example.com",
        "01212733888",
        "Hauck Inc",
        "6th year",
        "Holy See"
    ],
    [
        "Harold",
        "harold@example.com",
        "01212733888",
        "Metz Inc",
        "6th year",
        "Iran"
    ],
    [
        "Shannon",
        "shannon@example.com",
        "01212733888",
        "Zemlak Group",
        "6th year",
        "South Georgia"
    ],
    [
        "Robert",
        "robert@example.com",
        "01212733888",
        "Hoeger",
        "6th year",
        "San Marino"
    ],
    [
        "Noel",
        "noel@example.com",
        "01212733888",
        "Howell - Rippin",
        "6th year",
        "Germany"
    ],
    [
        "Traci",
        "traci@example.com",
        "01212733888",
        "Koelpin - Goldner",
        "6th year",
        "Vanuatu"
    ],
    [
        "Kerry",
        "kerry@example.com",
        "01212733888",
        "Feeney, Langworth and Tremblay",
        "6th year",
        "Niger"
    ],
    [
        "Patsy",
        "patsy@example.com",
        "01212733888",
        "Streich Group",
        "6th year",
        "Niue"
    ],
    [
        "Cathy",
        "cathy@example.com",
        "01212733888",
        "Ebert, Schamberger and Johnston",
        "6th year",
        "Mexico"
    ],
    [
        "Tyrone",
        "tyrone@example.com",
        "01212733888",
        "Raynor, Rolfson and Daugherty",
        "6th year",
        "Qatar"
    ],
]

const studentcolumns = [
    "Name",
    "Email",
    "Phone Number",
    "City",
    "Grade",
    "Country"
]

export {
    subdata,
    subcolumns,
    studentdata,
    studentcolumns,
    data,
    columns
};
